<template>
  <div class="page-container d-flex justify-center align-center h-100">
    <div class="text-h3 pb-12 font-weight-thin text-capitalize">
      {{ $t('text.welcomeTo', [$t('title.projectName')]) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
